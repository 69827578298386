
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

header {
	background: $navy;
	width: 100vw;
	box-shadow: 0px -3px 6px 3px $shadow-gray;

	:deep(.header-container) {
		max-width: 800px;
		margin: auto;
	}

	:deep(img) {
		height: 34px;
		margin: 10px 0;
	}
}
